<template>
  <div class="sk-page">
    <simple-table
      :table-column="tableColumn"
      :table-data="tableData"
      :table-page-info="tablePageInfo"
      :table-data-count="tableDataTotalCount"
      @page-info-change="handlePageInfoChange"
      @sort-change="handleSortChange"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-edit"
          :title="$t('operation.edit')"
          @click="handleEditClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-delete"
          :title="$t('operation.delete')"
          @click="deleteRecord(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>
<script>
/** 混入分页信息及数据总数信息 */
import pageMixins from '@/components/base/simple-table/mixin'
import detailDialog from './detail'
const BASEURL = {
  list: '/system/dict/list',
  delete: '/system/dict/delete'
}
export default {
  name: 'SystemDictionary',
  components: { detailDialog },
  mixins: [pageMixins],
  data () {
    return {
      searchModel: {},
      /** 表格数据 */
      tableData: [],
      rowData: {},
      /** 是否显示详情页 dialog */
      isShowDetailDialog: false,
      /** 是否为查看数据 */
      isViewFlag: false
    }
  },
  computed: {
    dictList () {
      const result = []
      const sysDict = this.$store.state.app.sysDict

      sysDict.forEach(dictItem => {
        if (result.indexOf(dictItem.type) < 0) result.push(dictItem.type)
      })

      return result.map(item => { return { key: item, value: item, label: item } })
    },

    searchFormField () {
      return [
        {
          prop: 'label',
          label: this.$t('system_dictionary.label'),
          type: 'Input',
          component: { optionList: this.dictList, filterable: true, clearable: true }
        },
        {
          prop: 'type',
          label: this.$t('system_dictionary.type'),
          type: 'Select',
          component: { optionList: this.dictList, filterable: true, clearable: true }
        },
        { prop: 'description', label: this.$t('system_dictionary.description'), type: 'Input', component: { clearable: true } },
        { slotName: 'tableOperation', col: { xs: 6, sm: 6, md: 6, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },
    tableColumn () {
      return [
        { label: this.$t('system_dictionary.label'), prop: 'label', minWidth: 180, sortable: 'custom' },
        { label: this.$t('system_dictionary.value'), prop: 'value', minWidth: 180, sortable: 'custom' },
        { label: this.$t('system_dictionary.type'), prop: 'type', minWidth: 260, sortable: 'custom' },
        { label: this.$t('system_dictionary.sort'), prop: 'sort', minWidth: 120, sortable: 'custom' },
        { label: this.$t('system_dictionary.description'), prop: 'description', minWidth: 200, sortable: 'custom' },

        { label: this.$t('field.operation'), slotName: 'operation', width: 100, align: 'center', fixed: 'right' }
      ]
    }
  },
  /**
   * 注入到子组件
   */
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({ page: this.tablePageInfo }, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = { pageNo: respData.pageNo, pageSize: respData.pageSize }
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.rowData = {}
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      this.isShowDetailDialog = true
    },
    handleEditClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },

    // 若需特殊处理排序规则，将此代码块打开
    // handleSortChange ({ column, prop, order }) {
    //   console.log(column, prop, order)
    // },

    deleteRecord (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    }
  }
}
</script>
<style lang="less" scoped>
.sk-page {
  width: 100%;
  height: 100%;
}
</style>
