<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_dictionary.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{ span: 24 }"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  get: '/system/dict/get',
  save: '/system/dict/save',
  update: '/system/dict/update'
}
export default {
  name: 'SystemDictionaryDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: {},

      companyList: [],
      companyLoadingFlag: false
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    dictList () {
      const result = []
      const sysDict = this.$store.state.app.sysDict

      sysDict.forEach(dictItem => {
        if (result.indexOf(dictItem.type) < 0) result.push(dictItem.type)
      })

      return result.map(item => { return { key: item, value: item, label: item } })
    },
    delFlag () { return this.$getDictList('del_flag') },

    formField () {
      return [
        {
          prop: 'label',
          label: this.$t('system_dictionary.label'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'value',
          label: this.$t('system_dictionary.value'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'type',
          label: this.$t('system_dictionary.type'),
          type: 'Select',
          viewFlag: !!this.detailData.id,
          rules: { trigger: 'change', message: this.$t('validate.notSelect'), required: true },
          component: { optionList: this.dictList, allowCreate: true, filterable: true }
        },
        {
          prop: 'sort',
          label: this.$t('system_dictionary.sort'),
          type: 'NumberInput',
          component: { min: 0 }
        },
        {
          prop: 'description',
          label: this.$t('system_dictionary.description'),
          type: 'Input',
          component: { type: 'textarea' },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true },
          col: { xs: 24, sm: 24, md: 24 }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

          const submitModel = this.$_.cloneDeep(this.formModel)

          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },

    handleDialogOpen () {
      if (!this.detailData.id) { return }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
